import { Suspense, lazy, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Drawer from './components/Drawer';
import Header from './components/Header';
import { PROGRAM_AUTHENTICATION_MODE, REGISTRATION_MODE } from './constants';
import { useGlobalState } from './store';
import { useProgramState } from './store/program';
import { getFaviconPath } from './utils';
import { initMqtt } from './utils/events';
import { setSentryUser } from './utils/sentry';

// import all pages lazily
const CompatibilityTest = lazy(() => import('./pages/CompatibilityTest'));
const Home = lazy(() => import('./pages/Home'));
const LanguageSelection = lazy(() => import('./pages/LanguageSelection'));
const LearningCenter = lazy(() => import('./pages/LearningCenter'));
const LiveSyncRegistrationLanding = lazy(
  () => import('./pages/LiveSyncRegistrationLanding'),
);
const Login = lazy(() => import('./pages/Login'));
const Logout = lazy(() => import('./pages/Logout'));
const NoLearningCenters = lazy(() => import('./pages/NoLearningCenters'));
const NoPrograms = lazy(() => import('./pages/NoPrograms'));
const NoRegistration = lazy(() => import('./pages/NoRegistration'));
const Program = lazy(() => import('./pages/Program'));
const Session = lazy(() => import('./pages/Session'));
const WalkInRegistration = lazy(() => import('./pages/WalkInRegistration'));
const Page = lazy(() => import('./pages/Page'));
const ZoomLobby = lazy(() => import('./pages/ZoomLobby'));
const ConferencingSessionLayout = lazy(
  () => import('./pages/Conferencing/layouts/SessionLayout'),
);

// const protectedRoutes: RouteObject[] = [
//   {
//     element: <ProtectedLayout />,
//     children: [
//       {
//         path: '/',
//         element: <Home />,
//       },
//       {
//         path: '/cmp',
//         element: <CompatibilityTest />,
//       },
//       {
//         path: '/program',
//         element: <Program />,
//       },
//       {
//         path: '/session',
//         element: <Session />,
//       },
//       {
//         path: '/learningCenter',
//         element: <LearningCenter />,
//       },
//       {
//         path: '/wir/:seed',
//         element: <WalkInRegistration />,
//       },
//       {
//         path: '/lsr/:seed',
//         element: <LiveSyncRegistrationLanding />,
//       },
//       {
//         path: '/anm/:seed',
//         element: <AnonymousRegistrationLanding />,
//       },
//       {
//         path: '/lngSel',
//         element: <LanguageSelection />,
//       },
//       {
//         path: '/noReg',
//         element: <NoRegistration />,
//       },
//       {
//         path: '/noPrg',
//         element: <NoPrograms />,
//       },
//       {
//         path: '/noLc',
//         element: <NoLearningCenters />,
//       },

//       {
//         path: '/login',
//         element: <Login />,
//       },
//       {
//         path: '/logout',
//         element: <Logout />,
//       },
//       // {
//       //   path: '*',
//       //   element: <Navigate to="/login" replace />,
//       // },
//       {
//         path: '*',
//         element: <NotFound />,
//       },
//     ],
//   },
// ];

const ProtectedRoute = (props: any) => {
  const { path, children } = props;
  const { userId } = useGlobalState();
  const { currentProgram } = useProgramState();
  if (currentProgram) {
    if (
      (currentProgram.authMode === PROGRAM_AUTHENTICATION_MODE.TOKEN ||
        currentProgram.registrationMode === REGISTRATION_MODE.ANONYMOUS) &&
      (path === '/session' || path === '/program')
    ) {
      return children;
    }
  }
  if (userId === null) {
    return <Navigate to={'/login'} replace={true} />;
  }
  return children;
};

const AppRouter = () => {
  const userId = useGlobalState((state) => state.userId);
  const currentProgram = useProgramState((state) => state.currentProgram);
  // const router = getRouter(userId);

  useEffect(() => {
    userId && initMqtt();
    userId && setSentryUser(userId);
  }, [userId]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{currentProgram ? currentProgram?.name : 'Events'}</title>
        <link
          rel="icon"
          type="image/svg+xml"
          href={
            currentProgram
              ? getFaviconPath(currentProgram.legalEntity)
              : '/src/assets/images/logo.svg'
          }
        />
      </Helmet>
      {/* <RouterProvider router={router} /> */}
      <BrowserRouter>
        <Drawer />
        <Header />
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute path="/">
                <Suspense fallback={<div>Loading</div>}>
                  <Home />
                </Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="/program"
            element={
              <Suspense fallback={<div>Loading</div>}>
                <Program />
              </Suspense>
            }
          />
          <Route
            path="/session"
            element={
              <ProtectedRoute path="/session">
                <Suspense fallback={<div>Loading</div>}>
                  <Session />
                </Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="/learningCenter"
            element={
              <ProtectedRoute path="/session">
                <Suspense fallback={<div>Loading</div>}>
                  <LearningCenter />
                </Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="/zoomLobby"
            element={
              <ProtectedRoute path="/zoomLobby">
                <Suspense fallback={<div>Loading</div>}>
                  <ZoomLobby />
                </Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="/conferencing/:sessionId"
            element={
              <Suspense fallback={<div>Loading</div>}>
                <ConferencingSessionLayout />
              </Suspense>
            }
          />
          <Route
            path="/wir/:seed"
            element={
              <Suspense fallback={<div>Loading</div>}>
                <WalkInRegistration />
              </Suspense>
            }
          />
          <Route
            path="/lsr/:seed"
            element={
              <Suspense fallback={<div>Loading</div>}>
                <LiveSyncRegistrationLanding />
              </Suspense>
            }
          />
          {/* <Route
            path="/anm/:seed"
            element={
              <Suspense fallback={<div>Loading</div>}>
                <AnonymousRegistrationLanding />
              </Suspense>
            }
          /> */}
          <Route
            path="/cmp"
            element={
              <Suspense fallback={<div>Loading</div>}>
                <CompatibilityTest />
              </Suspense>
            }
          />
          <Route
            path="/noReg"
            element={
              <Suspense fallback={<div>Loading</div>}>
                <NoRegistration />
              </Suspense>
            }
          />
          <Route
            path="/noPrg"
            element={
              <Suspense fallback={<div>Loading</div>}>
                <NoPrograms />
              </Suspense>
            }
          />
          <Route
            path="/noLc"
            element={
              <Suspense fallback={<div>Loading</div>}>
                <NoLearningCenters />
              </Suspense>
            }
          />
          <Route
            path="/lngSel"
            element={
              <Suspense fallback={<div>Loading</div>}>
                <LanguageSelection />
              </Suspense>
            }
          />
          <Route
            path="/page/:id?"
            element={
              <Suspense fallback={<div>Loading</div>}>
                <Page />
              </Suspense>
            }
          />
          {/* <Route path="/lab" element={<Lab />} /> */}
          <Route
            path="/login"
            element={
              <Suspense fallback={<div>Loading</div>}>
                <Login />
              </Suspense>
            }
          />
          <Route
            path="/logout"
            element={
              <Suspense fallback={<div>Loading</div>}>
                <Logout />
              </Suspense>
            }
          />
        </Routes>
        {/* <Footer /> */}
      </BrowserRouter>
    </>
  );
};

export default AppRouter;

/*

  <>
        <BrowserRouter>
          <Drawer />
          <Header />
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute path="/">
                  <Suspense fallback={<div>Loading</div>}>
                    <Home />
                  </Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path="/program"
              element={
                <Suspense fallback={<div>Loading</div>}>
                  <Program />
                </Suspense>
              }
            />
            <Route
              path="/session"
              element={
                <ProtectedRoute path="/session">
                  <Suspense fallback={<div>Loading</div>}>
                    <Session />
                  </Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path="/learningCenter"
              element={
                <ProtectedRoute path="/session">
                  <Suspense fallback={<div>Loading</div>}>
                    <LearningCenter />
                  </Suspense>
                </ProtectedRoute>
              }
            />
            <Route path="/wir/:seed" element={<WalkInRegistration />} />
            <Route path="/lsr/:seed" element={<LiveSyncRegistrationLanding />} />
            <Route path="/anm/:seed" element={<AnonymousRegistrationLanding />} />
            <Route path="/cmpTest" element={<CompatibilityTest />} />
            <Route
              path="/noReg"
              element={
                <Suspense fallback={<div>Loading</div>}>
                  <NoRegistration />
                </Suspense>
              }
            />
            <Route
              path="/noPrg"
              element={
                <Suspense fallback={<div>Loading</div>}>
                  <NoPrograms />
                </Suspense>
              }
            />
            <Route
              path="/noLc"
              element={
                <Suspense fallback={<div>Loading</div>}>
                  <NoLearningCenters />
                </Suspense>
              }
            />
            <Route path="/lngSel" element={<LanguageSelection />} />
            <Route path="/lab" element={<Lab />} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
          </Routes>
        </BrowserRouter>
      </>
*/
